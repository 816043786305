import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
import "@material/react-text-field/dist/text-field.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import GuidedTourProvider from "contexts/GuidedTourContext";
import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import TagManager from "react-gtm-module";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { urlDecider } from "tools/api";
import { AlertAttentionProvider } from "../contexts/AlertAttentionContext";
import { AlertContextProvider } from "../contexts/AlertContext/parent";
import { AuthProvider } from "../contexts/AuthContext";
import { ConstantsProvider } from "../contexts/ConstantsContext/parent";
import { DimensionProvider } from "../contexts/DimensionContext";
import { FilePreviewProvider } from "../contexts/FilePreviewContext/parent";
import { GoogleDrivePreviewProvider } from "../contexts/GoogleDrivePreviewContext/parent";
import { InvoiceProvider } from "../contexts/InvoiceContext";
import { LayoutProvider } from "../contexts/Layout/parent";
import TaskProvider from "../contexts/TaskContext/parent";
import { TimerProvider } from "../contexts/TimerContext";
import { ToasterProvider } from "../contexts/ToasterContext";
import TranslationProvider from "../contexts/TranslationContext/parent";
import ErrorBoundary from "../errorBoundaries";
import SEOComponents from "../pageComponents/script/seo";
import "../styles/badge.css";
import "../styles/button.css";
import "../styles/draftjs.css";
import "../styles/globals.css";
import "../styles/icon.css";
import "../styles/input.css";
import "../styles/modals.css";
import "../styles/sidebar.css";
import "../styles/slick.css";
import "../styles/summarydetails.css";
import "../styles/table.scss";
import "../styles/text.css";
import "../styles/toaster.css";
import "../styles/tooltip.css";
import "../styles/transition.css";
import { initEvents } from "../universalFunctions/events";

const config = {
  app_id: `${process.env.NEXT_PUBLIC_MOENGAGE_APP_ID}`,
  cluster: "DC_1",
  swPath: "/serviceworker.js",
  swScope: "/",
  enableSPA: true,
  debug_logs: 0,
};

const OpenReplay = ({ children }: { children: React.ReactNode }) => {
  const activeApi = urlDecider(
    process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL
  );
  const __html = `
  var initOpts = {
    projectKey: "y4LlsLLMqUfoG4trspQL",
    ingestPoint: "https://openreplay.transfez-jack-engineering.xyz/ingest",
    defaultInputMode: 1,
    obscureTextNumbers: true,
    obscureTextEmails: true,
    network: {
      capturePayload: true,
      ignoreHeaders: true,
      sanitizer: (res) => {
         try {
          // Ensure the request body is a string before parsing

          const isOwnApi = res.url.includes("${activeApi}");

          const isString = typeof res.request.body !== "string";

          if (!isOwnApi || !isString) return res;

          const rawBody = res.request.body;
          const rawObj = JSON.parse(rawBody) || {};

          let obj = {};

          const keys = Object.keys(rawObj);

          keys.forEach((key) => {
            const isPassword = key.toLowerCase().includes("pass");
            const isPin = key.toLowerCase().includes("pin");

            if (isPassword || isPin) {
              obj[key] = "****"; // Mask the sensitive data
            } else {
              obj[key] = rawObj[key];
            }
          });

          // Re-assign the sanitized object back to the request body
          res.request.body = JSON.stringify(obj);
          return res;
        } catch (error) {
          // Handle parsing errors
          console.error("Error in sanitizer function: ", error);
          return res;
        }
      },
    },
  };
  var startOpts = { userID: "" };
  (function(A,s,a,y,e,r){
    r=window.OpenReplay=[e,r,y,[s-1, e]];
    s=document.createElement('script');s.src=A;s.async=!a;
    document.getElementsByTagName('head')[0].appendChild(s);
    r.start=function(v){r.push([0])};
    r.stop=function(v){r.push([1])};
    r.setUserID=function(id){r.push([2,id])};
    r.setUserAnonymousID=function(id){r.push([3,id])};
    r.setMetadata=function(k,v){r.push([4,k,v])};
    r.event=function(k,p,i){r.push([5,k,p,i])};
    r.issue=function(k,p){r.push([6,k,p])};
    r.isActive=function(){return false};
    r.getSessionToken=function(){};
  })("//static.openreplay.com/13.0.0/openreplay-assist.js",1,0,initOpts,startOpts);
`;

  const inactiveArr = ["localhost"];

  const isActive = !inactiveArr.find((string) =>
    window.location.href.includes(string)
  );

  return (
    <>
      {isActive && (
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html,
          }}
        />
      )}
      {children}
    </>
  );
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(initEvents, []);
  useEffect(() => {
    if (typeof window !== "undefined") {
      import("@moengage/web-sdk")
        .then((MoEngage) => MoEngage.default.initialize(config))
        .catch((err) => console.error("MoEngage SDK loading failed", err));
    }
  }, []);

  const tagManagerArgs = { gtmId: "GTM-567HLBD" };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  const { query, pathname, locale, replace } = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") return;

    if (locale != "id") return;
    replace(pathname, query, { locale: false });
  }, [locale]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/serviceworker.js")
        .then((registration) => console.log("scope is: ", registration.scope));
    }
  }, []);

  return (
    <FpjsProvider
      loadOptions={{
        apiKey: String(process.env.NEXT_PUBLIC_FINGERPRINT),
        endpoint: ["https://ZhQbh9K4tf9t.itsjack.com", { default: "endpoint" }],
        scriptUrlPattern: [
          "https://ZhQbh9K4tf9t.itsjack.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
          "https://fpnpmcdn.net/v<version>/<apiKey>/loader_v<loaderVersion>.js",
        ],
        region: "ap",
      }}
    >
      <TranslationProvider>
        <DimensionProvider>
          <ToasterProvider>
            <AuthProvider>
              <AlertContextProvider>
                <InvoiceProvider>
                  <AlertAttentionProvider>
                    <TimerProvider>
                      <ConstantsProvider>
                        <GoogleDrivePreviewProvider>
                          <TaskProvider>
                            <FilePreviewProvider>
                              <SEOComponents />
                              <LayoutProvider>
                                <ErrorBoundary>
                                  <GuidedTourProvider>
                                    {/* <OpenReplay> */}
                                    <Component {...pageProps} />
                                    {/* </OpenReplay> */}
                                  </GuidedTourProvider>
                                </ErrorBoundary>
                              </LayoutProvider>
                            </FilePreviewProvider>
                          </TaskProvider>
                        </GoogleDrivePreviewProvider>
                      </ConstantsProvider>
                    </TimerProvider>
                  </AlertAttentionProvider>
                </InvoiceProvider>
              </AlertContextProvider>
            </AuthProvider>
          </ToasterProvider>
        </DimensionProvider>
      </TranslationProvider>
    </FpjsProvider>
  );
};

export default appWithTranslation(MyApp);
